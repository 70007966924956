import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import markdownToHTML from "../util/markdownToHTML";
const ProductDetails = (props) => {
  const { data } = props;
  const { details } = props?.pageContext;
  console.log(details);
  return (
    <Layout details={data}>
      <BreadCrumb
        list={[
          {
            title: "Products",
            href: "/products",
          },
          {
            title: details?.title,
            active: true,
          },
        ]}
      />
      <div className="bg-[#f7f7f7] bg-gradient-to-t from-gray-100 relative z-[1] product-section overflow-hidden">
        <div className="flex flex-wrap item-start bg-[#ffffffbf] p-8  xs:p-2">
          <div className="w-full md:w-2/6  flex justify-center items-start  md:pt-0 md:pl-2  hover:scale-110 transition-all duration-300">
            <img
              className=""
              src={details?.image?.asset?.url}
            />
          </div>
          <div className="w-full md:w-2/3 px-4 pl-10 xs:pl-4 xs:mt-6">
            <p className="font-semibold text-[1.4rem] text-slate-900 mb-1">
              {details.title}
            </p>
            <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
              {details?.product_category?.title}
            </span>
            <p
              className="text-justify text-gray-900 leading-7 mt-4"
              dangerouslySetInnerHTML={{
                __html: markdownToHTML(details?.detailed_description),
              }}
            ></p>
          </div>
        </div>
        <div className=" mx-auto px-16 py-24 max-w-7xl xs:px-3 product-section">
          <div className="py-7 flex flex-col gap-4">
            {details.sub_part_details &&
              details.sub_part_details.map((part, index) => {
                return (
                  <div className="flex  item-start xs:justify-center bg-white rounded-lg overflow-hidden shadow-sm xs:flex-wrap hover:shadow-xl hover:scale-105 transition-all duration-300 ">
                    <img
                      className="h-48 w-48 hover:scale-110 transition-all duration-300"
                      src={part?.image?.asset?.url}
                    />
                    <div className="py-3 px-4 xs:mt-1">
                      <p className="font-semibold text-[1rem] text-slate-900 mb-1">
                        {part?.title}
                      </p>
                      <p
                        className="text-justify text-gray-900 leading-7 mt-4"
                        dangerouslySetInnerHTML={{
                          __html: markdownToHTML(part?.description),
                        }}
                      ></p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
export default ProductDetails;
