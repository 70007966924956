import React from "react";
import { Link } from "gatsby";

const BreadCrumb = (props) => {
  const { list } = props;
  return (
    <nav
      className="flex sticky top-0 z-[9] bg-[#f6f7f7] shadow-sm py-[33px] px-[33px] "
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center" key="breadcrumb_0">
          <Link
            to={"/"}
            className="text-indigo-500 hover:text-indigo-700 inline-flex items-center"
          >
            <svg
              className="w-5 h-5 mr-2.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            Home
          </Link>
        </li>
        {list &&
          list.map((item, index) => {
            if (item?.active === true) {
              return (
                <li aria-current="page" key={`breadcrumb_` + (index + 1)}>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-400 ml-1 md:ml-2 text-sm font-medium">
                      {item?.title}
                    </span>
                  </div>
                </li>
              );
            } else {
              return (
                <li  key={`breadcrumb_` + (index + 1)}>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-indigo-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <Link
                      to={item?.href}
                      className="text-indigo-500 hover:text-indigo-700 ml-1 md:ml-2 text-sm font-medium"
                    >
                      {item?.title}
                    </Link>
                  </div>
                </li>
              );
            }
          })}
      </ol>
    </nav>
  );
};
export default BreadCrumb;
